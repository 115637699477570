import Vue from "vue";
import Vuex from "vuex";
import set from "lodash/set";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    id: "",
    campaign_specific: "",
    campaign_specifics: [],
    site_network: "",
    site_networks: [],
    seller: "",
    sellers: [],
    currency: "",
    currencies: [],
    floor_price: "",
    auction_type: "",
    auction_types: [],
    deal_type: "",
    deal_types: [],
    geo: "",
    geos: [],
    language: "",
    languages: [],
    targeting_type: "",
    targeting_types: [],
    targeting_category: "",
    targeting_categories: [],
    environment: "",
    environments: [],
    ssp: "",
    ssps: [],
    other: "",
    targeting_value: "",
    targeting_values: [],
    creative_type: "",
    creative_types: [],
    format_displays: [],
    format_videos: [],
    formats: "",
    run_type_section: "",
    run_type_sections: [],
    deal_name: "",
  },
  mutations: {
    /**
     * Set/mutate commited API data from Vue components
     * @type {array}
     */
    SET_API_DATA(state, payload) {
      set(state, payload.property, payload.value);
    },
    SET_DEAL_NAME(state, payload) {
      state.deal_name = payload;
    },
  },
  actions: {},
  modules: {},
});
