<template>
  <div id="app">
    <TheHeader />
    <transition name="route">
      <keep-alive>
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>

<style lang="scss">
// Import G-font
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

// Set defaults
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
ul,
li,
span,
div,
b,
u,
i,
label {
  font-family: "Montserrat", sans-serif;
}

// Transition effect
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
.route-enter-active,
.route-leave-acitve {
  transition: all 0.7s ease;
}
.route-enter-to,
.route-leave {
  opacity: 1;
  transform: translateY(0);
}
</style>

<script>
import TheHeader from "@/components/TheHeader.vue";
import axios from "axios";
export default {
  components: {
    TheHeader,
  },
  mounted() {
    /** Make concurrent HTTP requests using Axios all
     * and get back an equal number of responses,
     * then commit the response to respective Vuex states
     */
    axios
      .all([
        axios.get(process.env.VUE_APP_URL + "/campaign-specifics?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/site-networks?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/sellers?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/currencies?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/auction-types?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/deal-types?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/geos?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/languages?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/targeting-types?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/targeting-categories?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/environments?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/ssps?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/targeting-values?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/creative-types?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/format-displays?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/format-videos?_limit=-1"),
        axios.get(process.env.VUE_APP_URL + "/run-type-sections?_limit=-1"),
      ])
      .then((responseArr) => {
        this.commitApiData("campaign_specifics", responseArr[0].data);
        this.commitApiData("site_networks", responseArr[1].data);
        this.commitApiData("sellers", responseArr[2].data);
        this.commitApiData("currencies", responseArr[3].data);
        this.commitApiData("auction_types", responseArr[4].data);
        this.commitApiData("deal_types", responseArr[5].data);
        this.commitApiData("geos", responseArr[6].data);
        this.commitApiData("languages", responseArr[7].data);
        this.commitApiData("targeting_types", responseArr[8].data);
        this.commitApiData("targeting_categories", responseArr[9].data);
        this.commitApiData("environments", responseArr[10].data);
        this.commitApiData("ssps", responseArr[11].data);
        this.commitApiData("targeting_values", responseArr[12].data);
        this.commitApiData("creative_types", responseArr[13].data);
        this.commitApiData("format_displays", responseArr[14].data);
        this.commitApiData("format_videos", responseArr[15].data);
        this.commitApiData("run_type_sections", responseArr[16].data);
      });
  },
  methods: {
    /**
     * Commit API data fetched from Axios to Vuex store,
     * gets mounted from the start
     * @type {array}
     */
    commitApiData(property, value) {
      this.$store.commit("SET_API_DATA", {
        property: property,
        value: value,
      });
    },
  },
};
</script>
