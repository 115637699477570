import Vue from "vue";
import VueRouter from "vue-router";
import Form from "../views/Form.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Form",
    component: Form,
  },
  {
    path: "/result",
    name: "Result",
    component: () => import("../views/Result.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
