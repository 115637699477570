<template>
  <div>
    <b-container class="mt-5">
      <b-row>
        <b-col md="4">
          <div class="form-group">
            <label>ID</label>
            <input
              v-model="id"
              type="text"
              class="form-control"
              placeholder="e.g. MP457698"
            />
            <div class="error" v-if="!$v.id.required">
              * Field is required
            </div>
          </div>
          <div class="form-group">
            <label>Campaign Specific</label>
            <v-select
              v-model.trim="$v.campaign_specific.$model"
              as="label:value:id"
              :from="campaign_specifics"
            />
            <div class="error" v-if="!$v.campaign_specific.required">
              * Field is required
            </div>
          </div>
          <div class="form-group">
            <label>Site(s)/Network</label>
            <v-select
              v-model.trim="$v.site_network.$model"
              as="label:value:id"
              :from="site_networks"
            />
            <div class="error" v-if="!$v.site_network.required">
              * Field is required
            </div>
          </div>
          <div class="form-group">
            <label>Seller</label>
            <v-select
              v-model.trim="$v.seller.$model"
              as="label:value:id"
              :from="sellers"
            />
            <div class="error" v-if="!$v.seller.required">
              * Field is required
            </div>
          </div>
          <div class="form-group">
            <label>Currency</label>
            <v-select
              v-model.trim="$v.currency.$model"
              as="label:value:id"
              :from="currencies"
            />
            <div class="error" v-if="!$v.currency.required">
              * Field is required
            </div>
          </div>
          <div class="form-group">
            <label>Floorprice</label>
            <input
              v-model.trim="$v.floor_price.$model"
              type="number"
              step="0.01"
              class="form-control"
              placeholder="xx.xx"
            />
            <div class="error" v-if="!$v.floor_price.required">
              * Field is required
            </div>
          </div>
          <div class="form-group">
            <label>Auction Type</label>
            <v-select
              v-model.trim="$v.auction_type.$model"
              as="label:value:id"
              :from="auction_types"
            />
            <div class="error" v-if="!$v.auction_type.required">
              * Field is required
            </div>
          </div>
          <div class="form-group">
            <label>Deal Type</label>
            <v-select
              v-model.trim="$v.deal_type.$model"
              as="label:value:id"
              :from="deal_types"
            />
            <div class="error" v-if="!$v.deal_type.required">
              * Field is required
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="form-group">
            <label>Geo</label>
            <v-select
              v-model.trim="$v.geo.$model"
              as="label:value:id"
              :from="geos"
            />
            <div class="error" v-if="!$v.geo.required">
              * Field is required
            </div>
          </div>
          <div class="form-group">
            <label>Language</label>
            <v-select
              v-model.trim="$v.language.$model"
              as="label:value:id"
              :from="languages"
            />
            <div class="error" v-if="!$v.language.required">
              * Field is required
            </div>
          </div>
          <div class="form-group">
            <label>Targeting Type</label>
            <v-select
              v-model.trim="$v.targeting_type.$model"
              as="label:value:id"
              :from="targeting_types"
            />
            <div class="error" v-if="!$v.targeting_type.required">
              * Field is required
            </div>
          </div>
          <div class="form-group">
            <label>Targeting Category</label>
            <select
              :disabled="
                targeting_type == 'na' || targeting_type == 'contextual'
              "
              v-model.trim="$v.targeting_category.$model"
              class="form-control"
            >
              <option v-for="t in targeting_categories" :key="t.id">{{
                t.value
              }}</option>
            </select>
            <div class="error" v-if="!$v.targeting_category.required">
              * Field is required
            </div>
          </div>
          <div class="form-group">
            <label>Environment</label>
            <v-select
              v-model.trim="$v.environment.$model"
              as="label:value:id"
              :from="environments"
            />
            <div class="error" v-if="!$v.environment.required">
              * Field is required
            </div>
          </div>
          <div class="form-group">
            <label>SSP</label>
            <v-select
              v-model.trim="$v.ssp.$model"
              as="label:value:id"
              :from="ssps"
            />
            <div class="error" v-if="!$v.ssp.required">
              * Field is required
            </div>
          </div>
          <div class="form-group">
            <label>Other</label>
            <input
              v-model.trim="$v.other.$model"
              type="text"
              class="form-control"
              placeholder="e.g. webtv, geo-8003, homebuyers, adv-miele, mieten, gaming"
            />
            <div class="error" v-if="!$v.other.required">
              * Field is required
            </div>
          </div>
          <div class="form-group">
            <label>Targeting Value</label>
            <select
              :disabled="targeting_type == 'na'"
              v-model.trim="$v.targeting_value.$model"
              class="form-control"
            >
              <option v-for="t in targeting_values" :key="t.id">{{
                t.value
              }}</option>
            </select>
            <div class="error" v-if="!$v.targeting_value.required">
              * Field is required
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="form-group">
            <label>Creative Type</label>
            <v-select
              v-model.trim="$v.creative_type.$model"
              as="label:value:id"
              :from="creative_types"
            />
            <div class="error" v-if="!$v.creative_type.required">
              * Field is required
            </div>
          </div>
          <transition name="route">
            <div v-if="creative_type == 'display'" class="form-group">
              <label><b>* Display Formats</b></label>
              <v-select
                v-model="formats"
                as="label:value:id"
                :from="format_displays"
                :close-on-select="false"
                :clear-on-select="false"
                :multiple="true"
              />
            </div>
          </transition>
          <transition name="route">
            <div v-if="creative_type == 'video'" class="form-group">
              <label><b>* Video Formats</b></label>
              <v-select
                v-model="formats"
                as="label:value:id"
                :from="format_videos"
                :close-on-select="false"
                :clear-on-select="false"
                :multiple="true"
              />
            </div>
          </transition>
          <div class="form-group">
            <label>Formats</label>
            <input
              v-model.trim="$v.formats.$model"
              disabled="disabled"
              type="text"
              class="form-control"
              placeholder="Select Creative Type..."
            />
            <div class="error" v-if="!$v.formats.required">
              * Field is required
            </div>
          </div>
          <div class="form-group">
            <label>Run Type / Section:</label>
            <v-select
              v-model.trim="$v.run_type_section.$model"
              as="label:value:id"
              :from="run_type_sections"
            />
            <div class="error" v-if="!$v.run_type_section.required">
              * Field is required
            </div>
          </div>
          <div>
            <span
              id="disabled-wrapper"
              class="d-inline-block w-100"
              tabindex="0"
            >
              <b-button
                variant="danger w-100"
                :disabled="$v.$invalid"
                @click="getCode()"
              >
                Submit
              </b-button>
            </span>
            <b-tooltip target="disabled-wrapper"
              >Fill out the form first!</b-tooltip
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
::-webkit-input-placeholder {
  font-size: 12px;
}
.error {
  color: red;
  font-size: 10px;
}
.form-control {
  max-height: 36px;
}
.btn-danger {
  font-size: 12px;
}
</style>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "Form",
  data() {
    return {
      id: "",
      campaign_specific: "",
      site_network: "",
      seller: "",
      currency: "",
      floor_price: "",
      auction_type: "",
      deal_type: "",
      geo: "",
      language: "",
      targeting_type: "",
      targeting_category: "",
      environment: "",
      ssp: "",
      other: "",
      targeting_value: "",
      creative_type: "",
      formats: [],
      run_type_section: "",
      formats_unique: [],
    };
  },
  computed: {
    // Import(map) below-listed state data from vuex store
    ...mapState([
      "campaign_specifics",
      "site_networks",
      "sellers",
      "currencies",
      "auction_types",
      "deal_types",
      "geos",
      "languages",
      "targeting_types",
      "targeting_categories",
      "environments",
      "ssps",
      "targeting_values",
      "creative_types",
      "format_displays",
      "format_videos",
      "run_type_sections",
    ]),
  },
  watch: {
    targeting_type(fieldVal) {
      var isEmptyType = fieldVal == "na";
      var isEmptyCategory = this.targeting_category == "na";
      var isEmptyValue = this.targeting_value == "na";
      if (isEmptyType) {
        this.targetingNa();
      } else if ((!isEmptyType && isEmptyCategory) || isEmptyValue) {
        this.targetingEmpty();
      } else if (fieldVal == "contextual") {
        this.targeting_category = "keyword";
      } else if (
        fieldVal != "contextual" &&
        this.targeting_category == "keyword"
      ) {
        this.targeting_category = "";
      }
    },
    targeting_category(fieldVal) {
      var isEmptyCategory = fieldVal == "na";
      var isEmptyType = this.targeting_type == "na";

      if (isEmptyCategory && !isEmptyType) {
        this.targeting_category = "";
      } else if (fieldVal == "keyword" && this.targeting_type != "contextual") {
        this.targeting_category = "";
      }
    },
    targeting_value(fieldVal) {
      var isEmptyValue = fieldVal == "na";
      var isEmptyType = this.targeting_type == "na";
      if (isEmptyValue && !isEmptyType) {
        this.targeting_value = "";
      }
    },
    creative_type(fieldVal) {
      if (fieldVal == "native-video" || fieldVal == "native-display") {
        this.formats.push("native");
      } else if (fieldVal == "audio") {
        this.formats.push("audio");
      } else if (fieldVal == "display" || fieldVal == "video") {
        this.formats = [];
      }
    },
  },
  methods: {
    getCode() {
      this.generateDeal();
      this.$router.push("/result");
    },
    targetingNa() {
      this.targeting_category = "na";
      this.targeting_value = "na";
    },
    targetingEmpty() {
      this.targeting_category = "";
      this.targeting_value = "";
    },
    generateDeal() {
      this.$store.commit(
        "SET_DEAL_NAME",
        `(cs_${this.campaign_specific})(pu_${this.site_network})(se_${this.seller})(ge_${this.geo})(la_${this.language})(tt_${this.targeting_type})(tc_${this.targeting_category})(tv_${this.targeting_value})(ct_${this.creative_type})(fo_${this.formats})(rt_${this.run_type_section})(cu_${this.currency})(fp_${this.floor_price})(at_${this.auction_type})(dt_${this.deal_type})(ev_${this.environment})(sp_${this.ssp})(ot_${this.other})`
      );
    },
  },
  validations: {
    id: {
      required,
    },
    campaign_specific: {
      required,
    },
    site_network: {
      required,
    },
    seller: {
      required,
    },
    geo: {
      required,
    },
    language: {
      required,
    },
    targeting_type: {
      required,
    },
    targeting_category: {
      required,
    },
    targeting_value: {
      required,
    },
    creative_type: {
      required,
    },
    formats: {
      required,
    },
    run_type_section: {
      required,
    },
    currency: {
      required,
    },
    floor_price: {
      required,
    },
    auction_type: {
      required,
    },
    deal_type: {
      required,
    },
    environment: {
      required,
    },
    ssp: {
      required,
    },
    other: {
      required,
    },
  },
};
</script>
