import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@desislavsd/vue-select/dist/vue-select.css";
import VueSelect from "@desislavsd/vue-select";
import Clipboard from "v-clipboard";
import Vuelidate from "vuelidate";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
Vue.use(Vuelidate);
Vue.use(Clipboard);
Vue.use(VueSelect, {});
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;
Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [process.env.VUE_APP_ORIGIN, /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
