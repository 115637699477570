<template>
  <div>
    <b-container fluid>
      <b-row class="menu">
        <b-container>
          <b-row>
            <b-col md="5">
              <ul class="list-inline">
                <li class="list-inline-item mr-5">
                  <BIconFileMedicalFill class="mr-2" />
                  <router-link
                    to="/"
                    :class="[
                      $route.path == '/' ? 'text-dark' : 'text-secondary',
                    ]"
                  >
                    Fill the form
                  </router-link>
                </li>
                <li class="list-inline-item text-secondary">
                  <BIconExclude class="mr-2" />
                  <span
                    id="disabled-wrapper-1"
                    class="d-inline-block"
                    tabindex="0"
                  >
                    <a
                      href="#"
                      :class="[
                        $route.path == '/result'
                          ? 'text-dark'
                          : 'text-secondary',
                      ]"
                    >
                      Get the code
                    </a>
                  </span>
                  <b-tooltip target="disabled-wrapper-1"
                    >Fill out the form first!</b-tooltip
                  >
                </li>
              </ul>
            </b-col>
            <b-col offset-md="4" md="3" class="order-first order-md-last">
              <router-link to="/">
                <img
                  class="img-fluid"
                  width="230"
                  src="@/assets/logo-main.svg"
                  alt="logo"
                />
              </router-link>
            </b-col>
          </b-row>
        </b-container>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
.menu {
  background: #f3f3f3;
  padding: 30px 0;
  ul li {
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        color: inherit;
      }
    }
  }
}
</style>

<script>
import { BIconFileMedicalFill, BIconExclude } from "bootstrap-vue";
export default {
  components: {
    BIconFileMedicalFill,
    BIconExclude,
  },
};
</script>
